$header-height: 45px;
$footer-height: 61px;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * www.studybuckets.com (monthly pageviews: <50K)
 *
 */

/* complete */

@font-face {
  font-family: "CircularXXWeb-Medium";
  src: url("../../constants/CircularXXWeb-Medium.woff") format("woff");

}

@font-face {
  font-family: "CircularXXWeb-Medium";
  src: url("../../constants/CircularXXWeb-Medium.woff2") format("woff2");
}




.ant-layout {
  background: #FFFFFF;
}


.app {
  .main-content {
    overflow-y: auto;
    overflow-x: hidden;
    line-height:normal;
    z-index: 1;
    min-height: calc(100vh - #{$header-height + $footer-height});
  }
}

.button-row {
  margin-top: 10px;
  &.right-aligned {
    float: right;
    button {
      margin-right: 10px;

    }
  }

  &:not(.right-aligned) {
    button {
      margin-left: 10px;
    }
  }
}






.app {
  font-family: "CircularXXWeb-Medium";
  font-variant-numeric: normal;
}

.ant-btn[disabled] {
  background: #EEF0F3;
}

body {
  overflow-x: hidden;
}


